var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo d-flex align-items-center"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("HerdOnCloud")])],1),_c('b-card-title',{staticClass:"mb-1"}),_c('b-card-text',{staticClass:"mb-2"}),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{ref:"registerFor1m",staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":_vm.$t('companyName'),"label-for":"register-companyname"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-companyname","name":"register-companyname","state":errors.length > 0 ? false : null,"placeholder":"iliksoft"},model:{value:(_vm.companyname),callback:function ($$v) {_vm.companyname=$$v},expression:"companyname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors.length > 0 ? false : null,"placeholder":"info@iliksoft.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('name'),"label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('lastName'),"label-for":"register-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-last-name","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('mobile'),"label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+############'),expression:"'+############'"}],attrs:{"id":"register-mıbike","state":errors.length > 0 ? false : null,"placeholder":"+905555555555"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":_vm.$t('password')}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t("register"))+" ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2 w-100"},[_c('b-button',{staticClass:"w-100",attrs:{"variant":"danger","to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("login"))+" ")])])],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"})]),_c('div',{staticClass:"auth-footer-btn d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"facebook","href":"javascript:void(0)"}},[_c('feather-icon',{attrs:{"icon":"FacebookIcon"}})],1),_c('b-button',{attrs:{"variant":"twitter","href":"javascript:void(0)"}},[_c('feather-icon',{attrs:{"icon":"TwitterIcon"}})],1),_c('b-button',{attrs:{"variant":"google","href":"javascript:void(0)"}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-button',{attrs:{"variant":"github","href":"javascript:void(0)"}},[_c('feather-icon',{attrs:{"icon":"GithubIcon"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }